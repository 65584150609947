<template>
    <div class="row justify-content-center min-vh-100 align-items-center loginBG">
        <div class="col-12 col-md-5 col-lg-4 sBlock">
            <div class="authincation-content">
                <div class="row no-gutters">
                    <div class="col-xl-12">
                        <div class="auth-form">
                            <div class="text-center mb-3">
                                <a href="/">
                                    <img src="../../assets/images/logo.png" alt="">
                                </a>
                            </div>
                            <h4 class="text-center mb-4">Sign in your account</h4>
                            <form @submit.prevent="validateInput" ref="login">
                                <div class="form-group">
                                    <label class="mb-1 text-black"><strong>Email</strong></label>
                                    <input type="email" class="form-control" value="mail@example.com" v-model="email">
                                </div>
                                <div class="form-group">
                                    <label class="mb-1 text-black"><strong>Password</strong></label>
                                    <input type="password" class="form-control" value="Password" minlength="8" v-model="password">
                                </div>
                                <div class="form-row d-flex justify-content-between mt-4 mb-2">
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox ml-1">
                                            <input type="checkbox" class="custom-control-input" id="basic_checkbox_1">
                                            <label class="custom-control-label text-black" for="basic_checkbox_1">Remember my preference</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <router-link :to="{name:'ResetPassword'}" class="text-black">
                                            Forgot Password?
                                        </router-link>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button type="submit" class="btn btn-primary btn-block">Sign Me In</button>
                                </div>
                            </form>
                            <div class="new-account mt-3">
                                <p class="text-black">Don't have an account?
                                    <router-link class="text-primary" :to="{name: 'Register'}">Sign up</router-link>
                                </p>
                            </div>
                          <DownloadAPK/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import methodsMixin from "../../utils/methodsMixin";
import DownloadAPK from "@/components/home/DownloadAPK.vue";

export default {
    name: "Login",
  components: {DownloadAPK},
    data(){
        return {
            email: '',
            password: ''
        }
    },
    mixins: [methodsMixin],
    methods: {
        async proceed(){
            const loader = this.$loading.show({container: this.$refs.login});
            const response = await this.$store.dispatch('auth/login', {
                email: this.email,
                password: this.password
            });
            loader.hide();
            if(response.status){
                toastr.success("Login successful");
                return this.$router.push({name: 'Dashboard'})
            }else{
                toastr.error(response.message, 'Error')
            }
        },
        validateInput(){
            if(!this.email || !this.password){
                return toastr.warning("Fill in all fields", "Oops!")
            }
            if(!this.validateEmail(this.email)){
                return toastr.warning("Enter a valid email address", "Oops!")
            }
            if(this.password.length < 8){
                return toastr.warning("The password length should be greater than 7 characters", "Oops!")
            }
            return this.proceed()
        }
    }
}
</script>

<style scoped>
.loginBG{
  background: url('../../assets/images/background/recoverBg.jpg') no-repeat center center;
  background-size: cover;
}
.loginBG:after{
  content:"";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
}
.sBlock{
  z-index: 3;
}
.authincation-content{
  background-color: rgba(255,255,255,0.8);
}
</style>